<template>
    <div class="pc reserva">

        <!-- Spiner -->
        <div v-if="isPreLoad" class="preload">
            <div class="preload-wrapper"></div>
            <div class="preload-dots">
                <div class="hollow-dots-spinner">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                </div>
            </div>
        </div>
        <!--Fin spiner -->

        <NavbarGuest :translator="translator" :user="user" :booking="booking" />

        <!-- Imatge 0 de la propietat -->
        <section v-if="booking._image_format" class="foto-bienvenida" :style="booking._image_format"></section>

        <div class="container mt-4">

            <h1 v-cloak class="mb-4">
                <span>
                    <i class="fal fa-comments pr-1"></i>
                </span>
                *Testimonis
                {{ translator.testimonis }}                
            </h1>

            <section v-if="testimonis.length >= 1">
                <div class="mb-3">
                    <label for="titular" class="">*Titular</label>
                    <p>{{ testimonis.titular }}</p>
                </div>
                <div class="mb-3">
                    <label for="positiu" class=""><i class="fa-light fa-face-smile"></i> *Qué te gustó más</label>
                    <p>{{ testimonis.text_positiu }}</p>
                </div>
                <div class="mb-3">
                    <label for="negatiu" class=""><i class="fa-light fa-face-frown-slight"></i> *Qué no te gustó</label>
                    <p>{{ testimonis.text_negatiu }}</p>
                </div>

                <div class="d-sm-flex align-items-center estrellas">
                    <span>{{ translator.preg1 }} *Limpieza:</span> <rate class="comp-estrella" style="display:flex;" :value="testimonis.preg1" :length="5" :ratedesc="stringDescription" :disabled="true"/>
                </div>
                <div class="d-sm-flex align-items-center estrellas">
                <span>{{ translator.preg2 }} *Equipamiento:</span> <rate class="comp-estrella" style="display:flex;" :value="testimonis.preg2" :length="5" :ratedesc="stringDescription" :disabled="true"/>
                </div>
                <div class="d-sm-flex align-items-center estrellas">
                    <span>{{ translator.preg3 }} *Servicio:</span> <rate class="comp-estrella" style="display:flex;" :value="testimonis.preg3" :length="5" :ratedesc="stringDescription" :disabled="true"/>
                </div>
                <div class="d-sm-flex align-items-center estrellas">
                    <span>{{ translator.preg4 }} *Entorno:</span> <rate class="comp-estrella" style="display:flex;" :value="testimonis.preg4" :length="5" :ratedesc="stringDescription" :disabled="true"/>
                </div>
                <div class="d-sm-flex align-items-center estrellas">
                    <span>{{ translator.preg5 }} *Tranquilidad:</span> <rate class="comp-estrella" style="display:flex;" :value="testimonis.preg5" :length="5" :ratedesc="stringDescription" :disabled="true"/>
                </div>

                <!-- <pre>{{ testimonis }}</pre> -->

            </section>
            <section v-else>
                <form>
                    <div class="mb-3">
                        <label for="titular" class="">*Titular</label>
                        <input type="text" class="form-control" v-model="titular">
                    </div>
                    <div class="mb-3">
                        <label for="positiu" class=""><i class="fa-light fa-face-smile"></i> *Qué te gustó más</label>
                        <textarea class="form-control" v-model="text_positiu"></textarea>
                    </div>
                    <div class="mb-3">
                        <label for="negatiu" class=""><i class="fa-light fa-face-frown-slight"></i> *Qué no te gustó</label>
                        <textarea class="form-control" v-model="text_negatiu"></textarea>
                    </div>

                    <div class="d-sm-flex align-items-center estrellas">
                        <span>{{ translator.preg1 }} *Limpieza:</span> <rate class="comp-estrella" style="display:flex;" v-model="preg1" :length="5" :ratedesc="stringDescription" />
                    </div>
                    <div class="d-sm-flex align-items-center estrellas">
                        <span>{{ translator.preg2 }} *Equipamiento:</span> <rate class="comp-estrella" style="display:flex;" v-model="preg2" :length="5" :ratedesc="stringDescription" />
                    </div>
                    <div class="d-sm-flex align-items-center estrellas">
                        <span>{{ translator.preg3 }} *Servicio:</span> <rate class="comp-estrella" style="display:flex;" v-model="preg3" :length="5" :ratedesc="stringDescription" />
                    </div>
                    <div class="d-sm-flex align-items-center estrellas">
                        <span>{{ translator.preg4 }} *Entorno:</span> <rate class="comp-estrella" style="display:flex;" v-model="preg4" :length="5" :ratedesc="stringDescription" />
                    </div>
                    <div class="d-sm-flex align-items-center estrellas">
                        <span>{{ translator.preg5 }} *Tranquilidad:</span> <rate class="comp-estrella" style="display:flex;" v-model="preg5" :length="5" :ratedesc="stringDescription" />
                    </div>
                    <button @click.prevent="enviaForm"  class="btn btn-primary mt-4">
                        {{ translator.finalitzar }}
                    </button>
                </form>
            </section>
        </div>
    </div>
</template>

<script>
    import NavbarGuest from "@/components/NavbarGuest.vue";
    import { mapState } from "vuex";
    
    export default {
        name: "GuestReviews",
        components: {
            NavbarGuest
        },

        computed: {
            ...mapState("utils", ['user', 'translator']),
            ...mapState("guest", ['booking', 'testimonis'])
        },

        watch: {
            translator() {
                this.$store.dispatch("guest/getBookingID", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
                this.$store.dispatch("guest/getTestimoni", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking });
            },
            testimonis() {
                this.isPreLoad = false;
            },
        },

        async created() {
            try {
                //this.isPreLoad = true;
                if (this.translator) {
                    await this.$store.dispatch("guest/getTestimoni", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking });
                    this.isPreLoad = false;
                } else {
                    await this.$store.dispatch("utils/getUser", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
                }
            } catch (error) {
                console.error(error);
            }
        },

        data() {
            return {
                isPreLoad: false,
                stringDescription: ['Muy mal', 'Mal', 'Normal', 'Bien', 'Muy bien'],
                titular: '',
                text_positiu: '',
                text_negatiu: '',
                preg1: 0,
                preg2: 0,
                preg3: 0,
                preg4: 0,
                preg5: 0,
            };
        },
        methods: {
            enviaForm() {
                this.$store.dispatch("guest/postTestimoni", { 
                    usr: String(this.$route.query.id).replace(/[ ]/g, '+'),
                    reserva: this.user.user_booking,
                    preg1: this.preg1,
                    preg2: this.preg2,
                    preg3: this.preg3,
                    preg4: this.preg4,
                    preg5: this.preg5,
                    titular: this.titular,
                    text_positiu: this.text_positiu,
                    text_negatiu: this.text_negatiu,
                    vm : this,
                    msn: this.translator.guardat
                })
            }
        }
    }
</script>

<style scoped>
form label {
    font-size: 1em;
    margin-bottom: 10px;
}
.comp-estrella >>> button {
    padding: 3px;
}
.estrellas {
    margin: 5px 0;
}
.estrellas span {
    min-width: 120px;
    padding: 11px 0;
}
</style>